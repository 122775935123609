jQuery(document).ready(function($) {

    var theWindow = $(window);
    var singleProjectContainer = $('#single-project');
    var iButton = singleProjectContainer.find('.i');
    var xButton = singleProjectContainer.find('.x');
    var projectDesc = singleProjectContainer.find('.text');
    var scrollTop = 0;
    var lastScrollTop = 0;
    var firstScrollDist = 0;
    var projectScrollDist = 0;
    var projectSite = false;

    /**
     * set project height after all images are loaded
     */
    theWindow.on("load", function() {
        if (projectDesc.length > 0) {
            projectScrollDist = singleProjectContainer.height() - 300;
            projectSite = true;
        }
    });

    /**
     * show project description (or not) depending on scroll distance and scroll borders
     */
    theWindow.scroll(function() {

        if (projectSite && theWindow.width() > 768) {
            //
            // desktop
            //
            scrollTop = theWindow.scrollTop();

            if (scrollTop > lastScrollTop) {
                // scroll down
                if (scrollTop > firstScrollDist) {
                    // console.log('if');
                    // project description (desktop)
                    projectDesc.removeClass('in').addClass('out');

                    if (projectDesc.height() < 500) {
                        projectDesc.addClass('out');
                    }
                    else if (projectDesc.height() > 500 && projectDesc.height() < 1000) {
                        projectDesc.addClass('out-1000');
                    }
                    else if (projectDesc.height() > 1000 && projectDesc.height() < 1500) {
                        projectDesc.addClass('out-1500');
                    }
                    else {
                        projectDesc.addClass('out-2000');
                    }
                }
            } else {
                // scroll up
                // console.log(scrollTop + ' - ' + projectScrollDist);
                if (( scrollTop < projectScrollDist ) && ( scrollTop < (lastScrollTop - 20)) ) {
                    console.log('else');
                    // project description (desktop)
                    projectDesc.stop().removeClass('out').removeClass('out-1000').removeClass('out-1500').removeClass('out-2000');
                }
            }
            lastScrollTop = scrollTop;
        }
        else {
            //
            // mobile
            //
            scrollTop = theWindow.scrollTop();

            if (scrollTop > lastScrollTop) {
                if (scrollTop > firstScrollDist) {
                    // the i (mobile)
                    iButton.removeClass('in').addClass('out');
                }
            } else {
                if (scrollTop < projectScrollDist) {
                    // the i (mobile)
                    iButton.stop().removeClass('out').addClass('in');
                }
            }
            lastScrollTop = scrollTop;
        }
    });

    /**
     * on resize
     */
    theWindow.resize(function() {
        resizeFunction();
    });

    /**
     * resize
     */
    function resizeFunction() {
        if (projectSite && theWindow.width() <= 768) {
            projectDesc.addClass('out-1500');
        }
    }

    /**
     * the iButton
     */
    iButton.click(function() {
        projectDesc.removeClass('out').removeClass('out-1000').removeClass('out-1500').removeClass('out-2000').addClass('in');
    });

    /**
     * the X
     */
    xButton.click(function() {
        projectDesc.addClass('out-1000').removeClass('in');
    });

    //
    // intro
    //
    var intro = $('#intro');
    var introNavCover = $('#intro-nav-cover');

    // fade out after time
    if (intro.length) {
        setTimeout(function() {
            introOff();
        }, 10000);
    }

    // fade out on click
    intro.click(function() {
        introOff();
    });

    introNavCover.click(function() {
        introOff();
    });

    introNavCover.hover(
        function() {
            $('.pe').addClass('hovered');
        },
        function() {
            $('.pe').removeClass('hovered');
        }
    );

    var scroll = 0;
    theWindow.scroll(function() {
        if (intro.length) {
            if (scroll > 0) {
                introOff();
            }
            scroll++;
        }
    });

    /**
     * intro fade out & restore navigation
     */
    function introOff() {
        intro.fadeOut(200);
        introNavCover.fadeOut(200);
        $('.intro-').fadeIn(200);
    }

});